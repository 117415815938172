<script>
import { PolarArea } from 'vue-chartjs'
import { $themeColors } from '@themeConfig'

const chartColors = {
  primaryColorShade: '#836AF9',
  successColorShade: '#28dac6',
  warningColorShade: '#ffe802',
  warningLightColor: '#FDAC34',
  infoColorShade: '#299AFF',
  greyColor: '#4F5D70',
}

export default {
  extends: PolarArea,
  setup() {
    const options = {
      responsive: true,
      maintainAspectRatio: false,
      responsiveAnimationDuration: 500,
      legend: {
        position: 'right',
        labels: {
          usePointStyle: true,
          padding: 25,
          boxWidth: 10,
          fontColor: chartColors.labelColor,
        },
      },
      tooltips: {
        // Updated default tooltip UI
        shadowOffsetX: 1,
        shadowOffsetY: 1,
        shadowBlur: 8,
        shadowColor: chartColors.tooltipShadow,
        backgroundColor: $themeColors.light,
        titleFontColor: $themeColors.dark,
        bodyFontColor: $themeColors.dark,
      },
      scale: {
        scaleShowLine: true,
        scaleLineWidth: 1,
        ticks: {
          display: false,
          fontColor: chartColors.labelColor,
        },
        reverse: false,
        gridLines: {
          display: false,
        },
      },
      animation: {
        animateRotate: false,
      },
    }

    const data = {
      labels: [],
      datasets: [
        {
          backgroundColor: [
            chartColors.primaryColorShade,
            chartColors.warningColorShade,
            $themeColors.primary,
            chartColors.infoColorShade,
            chartColors.greyColor,
            chartColors.successColorShade,
          ],
          data: [],
          borderWidth: 0,
        },
      ],
    }
    return {
      options,
      data,
    }
  },
  mounted() {
    this.renderChart(this.data, this.options)
  },
  methods: {
    updateChar(_labels, _data) {
      const chartData = {
        labels: _labels,
        datasets: [
          {
            backgroundColor: [
              '#836AF9',
              '#FFE802',
              '#299AFF',
              '#28DAC6',
              '#EEEDFD',
              '#BE5743',
              '#FFB56E',
              '#EA5455',
              '#E5E5E5',
              '#28C76F',
              '#ce3df3',
              '#00D4FF',
            ],
            data: _data,
            borderWidth: 0,
          },
        ],
      }

      this.renderChart(chartData, this.options)
    },
  },
}
</script>
