<template>
  <b-card>
    <b-card-title class="mb-1">
      {{ title }}
    </b-card-title>
    <b-card-sub-title class="mb-2">
      {{ subtitle }}
    </b-card-sub-title>
    <chartjs-component-polar-area-chart
      ref="chartPolar"
      :height="350"
    />
    <b-table
      :items="groupSeries"
      :fields="seriesfields"
      responsive
      :foot-clone="true"
      class="mb-0"
    >
      <template #foot(nombre)>
        {{ $t('dashboards.chartjsPolar.highTotal') }}
      </template>
      <template #foot(valor)>
        {{ graficaPeriodoSumaTotal }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BCardTitle, BCardSubTitle, BTable,
} from 'bootstrap-vue'
import { ref } from '@vue/composition-api'
import ChartjsComponentPolarAreaChart from '@/views/dashboard/components/ChartjsComponentPolarAreaChart.vue'
import i18n from '@/libs/i18n'

export default {
  components: {
    BCard,
    BCardTitle,
    BCardSubTitle,
    BTable,
    ChartjsComponentPolarAreaChart,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
  },
  setup() {
    const groupSeries = ref([])
    const graficaPeriodoSumaTotal = ref(0)
    const seriesfields = [
      { key: 'nombre', label: i18n.t('dashboards.chartjsPolar.typeFinding') },
      { key: 'valor', label: i18n.t('dashboards.chartjsPolar.finding') },
    ]
    return {
      groupSeries,
      seriesfields,
      graficaPeriodoSumaTotal,
    }
  },
  methods: {
    updateChar(labels, series, groups, total) {
      this.$refs.chartPolar.updateChar(labels, series)
      this.groupSeries = groups
      this.graficaPeriodoSumaTotal = total
    },
  },
}
</script>
