<template>
  <b-card
    v-if="controlFindings"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="controlFindings.hallazgos"
      responsive
      :fields="fields"
      class="mb-0"
      :foot-clone="true"
    >
      <!-- Nombre -->
      <template #cell(auditoriaGrupoEventoNombre)="data">
        {{ data.item.nombre }}
      </template>

      <!-- Total -->
      <template #cell(total)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.total }}</span>
        </div>
      </template>

      <!-- Hallazgos -->
      <template #cell(hallazgos)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.hallazgos }}</span>
        </div>
      </template>

      <!-- porcentaje -->
      <template #cell(porcentaje)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.porcentaje + '%' }}</span>
        </div>
      </template>

      <!-- Calificacion -->
      <template #cell(calificacion)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.calificacion }}</span>
        </div>
      </template>

      <!-- comentarios -->
      <template #cell(comentarios)="data">
        {{ data.item.comentarios }}
      </template>

      <template #foot(auditoriaGrupoEventoNombre)>
        {{ $t('dashboards.auditoriaMolex.findingsControl.entiresAutoria') }}
      </template>
      <template #foot(total)>
        {{ controlFindings.total }}
      </template>
      <template #foot(hallazgos)>
        {{ controlFindings.totalHallazgos }}
      </template>
      <template #foot(porcentaje)>
        {{ controlFindings.porcentaje + '%' }}
      </template>
      <template #foot(comentarios)>
        {{ '' }}
      </template>
      <template #foot(calificacion)>
        {{ controlFindings.calificacion }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import i18n from '@/libs/i18n'
import {
  BCard,
  BTable,
  // BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    // BButton,
  },
  props: {
    // eslint-disable-next-line vue/require-prop-types
    controlFindings: {
      required: true,
    },
  },
  setup() {
    const fields = [
      { key: 'auditoriaGrupoEventoNombre', label: i18n.t('dashboards.auditoriaMolex.findingsControl.auditoria') },
      { key: 'total', label: i18n.t('dashboards.auditoriaMolex.findingsControl.entire') },
      { key: 'hallazgos', label: i18n.t('dashboards.auditoriaMolex.findingsControl.findings') },
      { key: 'porcentaje', label: i18n.t('dashboards.auditoriaMolex.findingsControl.percentage') },
      { key: 'comentarios', label: i18n.t('dashboards.auditoriaMolex.findingsControl.comments') },
      { key: 'Calificacion', label: i18n.t('dashboards.auditoriaMolex.findingsControl.rating') },
    ]

    return {
      fields,
    }
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
