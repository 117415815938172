<template>
  <b-card no-body>
    <b-card-header>
      <!-- title and subtitle -->
      <div>
        <b-card-title class="mb-1">
          {{ title }}
        </b-card-title>
        <b-card-sub-title>{{ subtitle }}</b-card-sub-title>
      </div>
      <!--/ title and subtitle -->
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        ref="lineChart"
        type="line"
        height="400"
        :options="chartOptions"
        :series="series"
      />
      <app-collapse
        v-if="graficaPeriodoSumasData"
      >
        <app-collapse-item :title="$t('dashboards.lineChart.details')">
          <b-table
            :items="graficaPeriodoSumasData"
            responsive
            :fields="graficaPeriodoSumasFields"
            :foot-clone="true"
            class="mb-0"
          >
            <template #foot(nombre)>
              {{ $t('dashboards.lineChart.highTotal') }}
            </template>
            <template #foot(totalDecimal)>
              {{ graficaPeriodoSumasTotal }}
            </template>
          </b-table>
        </app-collapse-item>
      </app-collapse>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BTable,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import { $themeColors } from '@themeConfig'
import i18n from '@/libs/i18n'
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BTable,

    AppCollapse,
    AppCollapseItem,
  },
  props: {
    title: {
      required: true,
    },
    subtitle: {
      required: true,
    },
  },
  data() {
    return {
      graficaPeriodoSumasData: [],
      graficaPeriodoSumasFields: [
        { key: 'nombre', label: i18n.t('dashboards.lineChart.periodLabel') },
        { key: 'totalDecimal', label: i18n.t('dashboards.lineChart.totalLabel') },
      ],
      graficaPeriodoSumasTotal: 0,
      series: [],
      chartOptions: {
        chart: {
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        markers: {
          strokeWidth: 7,
          strokeOpacity: 1,
          strokeColors: [$themeColors.light],
          colors: [$themeColors.warning],
        },
        colors: [$themeColors.warning],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          curve: 'smooth',
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
        },
        tooltip: {
          custom(data) {
            return `${'<div class="px-1 py-50"><span>'}${
              data.series[data.seriesIndex][data.dataPointIndex]
            }%</span></div>`
          },
        },
        xaxis: {
          categories: [],
        },
        yaxis: {
          min: 70,
          max: 100,
          // opposite: isRtl,
        },
      },
    }
  },
  methods: {
    updateChar(series, periodos, graficaPeriodoSumas, totalDecimal) {
      /*eslint-disable */ 
      this.chartOptions = {
        ...this.chartOptions, ...
        {
          xaxis: {
          categories: periodos
        }
      }}  
      
      this.$refs.lineChart.updateSeries(series)
      this.graficaPeriodoSumasData = graficaPeriodoSumas
      this.graficaPeriodoSumasTotal = totalDecimal
    },
  },    
}
</script>
