<template>
  <section id="dashboard-auditoria-molex">
    <!--Filters-->
    <auditoria-molex-filter
      @refetch-data="refetchData"
    />
    <b-row class="match-height">
      <!-- Control de hallazgos -->
      <b-col lg="12">
        <auditoria-molex-control-hallazgos
          :control-findings.sync="controlFindings"
        />
      </b-col>
      <!-- Hallazgos -->
      <b-col
        lg="12"
        md="12"
      >
        <auditoria-molex-eventos
          ref="refMolexEvents"
        />
      </b-col>
      <!-- Polar Hallazgos -->
      <b-col
        lg="12"
        md="12"
      >
        <chartjs-polar-chart
          ref="refDonoutChar"
          :title="$t('dashboards.auditoriaMolex.molex.categoryFindings')"
          :subtitle="$t('dashboards.auditoriaMolex.molex.findingsType')"
        />
      </b-col>
      <!--DataBar hallazgos-->
      <b-col
        lg="12"
        md="12"
      >
        <auditoria-molex-data-bar-hallazgo
          ref="refBarChar"
          :title="$t('dashboards.auditoriaMolex.molex.periodComparative')"
          :subtitle="$t('dashboards.auditoriaMolex.molex.findingsType')"
        />
      </b-col>
      <!--DataBar hallazgos year-->
      <b-col
        lg="12"
        md="12"
      >
        <auditoria-molex-data-bar-hallazgo
          ref="refYearBarChar"
          :title="$t('dashboards.auditoriaMolex.molex.currentYear')"
          subtitle=""
        />
      </b-col>
      <!--LineChart Calificacion-->
      <b-col
        lg="12"
        md="12"
      >
        <line-chart
          ref="refLineChartCalificacion"
          :title="$t('dashboards.auditoriaMolex.molex.yearQualification')"
          :subtitle="$t('dashboards.auditoriaMolex.molex.monthlyResult')"
        />
      </b-col>
      <!--DataBar Rectificaciones-->
      <b-col
        lg="6"
        md="6"
      >
        <auditoria-molex-data-bar-hallazgo
          ref="refYearBarCharRectificaciones"
          :title="$t('dashboards.auditoriaMolex.molex.rectificationsCurrentYear')"
          :subtitle="$t('dashboards.auditoriaMolex.molex.rectificationsByMonth')"
        />
      </b-col>
      <!--Donout Bar Rectificaciones-->
      <b-col
        lg="6"
        md="6"
      >
        <chartjs-doughnut-chart
          ref="refRectificacionDonoutChar"
          :title="$t('dashboards.auditoriaMolex.molex.rectificationsConsulted')"
          :subtitle="$t('dashboards.auditoriaMolex.molex.operationsCorrections')"
        />
      </b-col>
      <!-- Rectificaciones -->
      <b-col
        lg="8"
        md="8"
      >
        <auditoria-molex-rectificaciones
          ref=""
          :rectifications.sync="rectifications"
        />
      </b-col>
      <b-col
        lg="4"
        md="4"
      >
        <auditoria-molex-radial-bar-chart-rectificaciones
          ref="refRectificactionRadialChar"
          :title="$t('dashboards.auditoriaMolex.molex.comparisonRectifications')"
          :subtitle="$t('dashboards.auditoriaMolex.molex.customerCLLA')"
        />
      </b-col>
    </b-row>
  </section>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import { ref, onMounted } from '@vue/composition-api'
import dashboardService from '@/services/dashboard.service'
import store from '@/store'
import ChartjsDoughnutChart from '@/views/dashboard/ChartjsDoughnutChart.vue'
import AuditoriaMolexFilter from './AuditoriaMolexFilter.vue'
import AuditoriaMolexControlHallazgos from './AuditoriaMolexControlHallazgos.vue'
import ChartjsPolarChart from '../ChartjsPolarChart.vue'
import AuditoriaMolexEventos from './AuditoriaMolexEventos.vue'
import AuditoriaMolexDataBarHallazgo from './AuditoriaMolexDataBarHallazgos.vue'
import AuditoriaMolexRectificaciones from './AuditoriaMolexRectificaciones.vue'
import LineChart from '../LineChart.vue'
import AuditoriaMolexRadialBarChartRectificaciones from './AuditoriaMolexRadialBarChartRectificaciones.vue'

export default {
  components: {
    BRow,
    BCol,

    AuditoriaMolexFilter,
    AuditoriaMolexControlHallazgos,
    ChartjsPolarChart,
    AuditoriaMolexEventos,
    AuditoriaMolexDataBarHallazgo,
    AuditoriaMolexRectificaciones,
    LineChart,
    AuditoriaMolexRadialBarChartRectificaciones,
    ChartjsDoughnutChart,
  },
  setup() {
    /* Refs */
    const refMolexEvents = ref(null)
    const refDonoutChar = ref(null)
    const refBarChar = ref(null)
    const refYearBarChar = ref(null)
    const refLineChartCalificacion = ref(null)
    const refYearBarCharRectificaciones = ref(null)
    const refRectificacionDonoutChar = ref(null)
    const refRectificactionRadialChar = ref(null)
    /* Data */
    const controlFindings = ref([])
    const rectifications = ref([])

    const refetchData = () => {
      const {
        fetchAuditoriaMolexControlHallazgos,
        fetchAuditoriaMolexGrafica,
        fetchAuditoriaMolexGraficaCalificacion,
        fetchAuditoriaMolexRectificaciones,
        fetchAuditoriaMolexRectificacionesGraficas,
      } = dashboardService()

      const nowDate = new Date()
      const year = nowDate.getFullYear()
      const dateFrom = `${year}-01-01`
      const dateTo = `${year}-12-31`

      fetchAuditoriaMolexControlHallazgos({
        fechaDesde: store.state.auditoriaMolex.dateFrom,
        fechaHasta: store.state.auditoriaMolex.dateTo,
        aduana: store.state.auditoriaMolex.custom,
        clienteId: store.state.auditoriaMolex.customer,
      }, data => {
        controlFindings.value = data
      })

      fetchAuditoriaMolexRectificaciones({
        fechaDesde: store.state.auditoriaMolex.dateFrom,
        fechaHasta: store.state.auditoriaMolex.dateTo,
        aduana: store.state.auditoriaMolex.custom,
        clienteId: store.state.auditoriaMolex.customer,
      }, data => {
        rectifications.value = data.rectificaciones
      })
      fetchAuditoriaMolexRectificacionesGraficas({
        fechaDesde: store.state.auditoriaMolex.dateFrom,
        fechaHasta: store.state.auditoriaMolex.dateTo,
        aduana: store.state.auditoriaMolex.custom,
        clienteId: store.state.auditoriaMolex.customer,
      }, data => {
        refRectificactionRadialChar.value.updateChar(data.seriesAgrupadasValores)
      })

      fetchAuditoriaMolexGrafica({
        fechaDesde: store.state.auditoriaMolex.dateFrom,
        fechaHasta: store.state.auditoriaMolex.dateTo,
        aduana: store.state.auditoriaMolex.custom,
        clienteId: store.state.auditoriaMolex.customer,
      }, data => {
        refDonoutChar.value.updateChar(data.etiquetas, data.seriesAgrupadasValores, data.seriesAgrupadas, data.total)
        refBarChar.value.updateChar(data.columnaSeries, data.periodos, data.graficaPeriodoSumas, data.total)
        refRectificacionDonoutChar.value.updateChar(data.rectificacionesEtiquetas, data.rectificacionesSeriesAgrupadasValores)

        fetchAuditoriaMolexGrafica({
          fechaDesde: dateFrom,
          fechaHasta: dateTo,
          eventoTipoNombreCorto: 'RECTIS',
          aduana: store.state.auditoriaMolex.custom,
          clienteId: store.state.auditoriaMolex.customer,
        }, dataYear => {
          refYearBarCharRectificaciones.value.updateChar(dataYear.columnaSeries, dataYear.periodos, dataYear.graficaPeriodoSumas, dataYear.total)
        })

        fetchAuditoriaMolexGrafica({
          fechaDesde: dateFrom,
          fechaHasta: dateTo,
          aduana: store.state.auditoriaMolex.custom,
          clienteId: store.state.auditoriaMolex.customer,
        }, dataYear => {
          refYearBarChar.value.updateChar(dataYear.columnaSeries, dataYear.periodos, dataYear.graficaPeriodoSumas, dataYear.total)
        })

        fetchAuditoriaMolexGraficaCalificacion({
          fechaDesde: dateFrom,
          fechaHasta: dateTo,
          aduana: store.state.auditoriaMolex.custom,
          clienteId: store.state.auditoriaMolex.customer,
        }, dataYear => {
          refLineChartCalificacion.value.updateChar(dataYear.columnaSeries, dataYear.periodos, dataYear.graficaPeriodoSumas, dataYear.totalDecimal)
        })
      })

      refMolexEvents.value.refetchData()
    }

    onMounted(() => { refetchData() })

    return {
      // refs
      refMolexEvents,
      refDonoutChar,
      refBarChar,
      refYearBarChar,
      refLineChartCalificacion,
      refYearBarCharRectificaciones,
      refRectificacionDonoutChar,
      refRectificactionRadialChar,
      // Data
      controlFindings,
      rectifications,

      refetchData,
    }
  },
  data() {
    return {
      data: {
        ruta: process.env.VUE_APP_ADUACORE_API_URL,
      },
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/pages/dashboard-ecommerce.scss';
@import '@core/scss/vue/libs/chart-apex.scss';
</style>
