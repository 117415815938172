<template>
  <b-card
    v-if="rectifications"
    no-body
    class="card-company-table"
  >
    <b-table
      :items="rectifications"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <!-- Numero Referencia -->
      <template #cell(numeroReferencia)="data">
        {{ data.item.numeroReferencia }}
      </template>
      <!-- Numero Pedimento -->
      <template #cell(numeroPedimento)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.numeroPedimento }}</span>
        </div>
      </template>
      <!-- Clave Documento -->
      <template #cell(claveDocumento)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.claveDocumento }}</span>
        </div>
      </template>
      <!-- Fecha Rectificacion -->
      <template #cell(fechaRectificacion)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.fechaRectificacion }}</span>
        </div>
      </template>

      <!-- Pedimento Original -->
      <template #cell(pedimentoOriginal)="data">
        <div class="d-flex align-items-center">
          <span class="font-weight-bolder mr-1">{{ data.item.numeroPedimentoOriginal }}</span>
        </div>
      </template>

      <!-- Fecha Pago Original -->
      <template #cell(fechaPagoOriginal)="data">
        {{ data.item.fechaPagoOriginal }}
      </template>
      <!-- Cargo De -->
      <template #cell(cargoDe)="data">
        {{ data.item.cargoDe }}
      </template>
      <!-- Observaciones pedimento -->
      <template #cell(observacionesPedimento)="row">
        <b-form-checkbox
          v-model="row.detailsShowing"
          plain
          class="vs-checkbox-con"
          @change="row.toggleDetails()"
        >
          <span class="vs-label">{{ row.detailsShowing ? 'Ocultar' : 'Mostrar' }}</span>
        </b-form-checkbox>
      </template>
      <template #row-details="data">
        {{ data.item.observacionesPedimento }}
      </template>
      <!-- Original-->
      <template #cell(descargaOriginal)="data">
        <b-button
          v-if="data.item.guidArchivoPedimentoBorradorOriginal"
          variant="success"
          @click="download(data.item.guidArchivoPedimentoBorradorOriginal)"
        >
          {{ $t('dashboards.auditoriaMolex.rectifications.download') }}
          <feather-icon
            icon="DownloadCloudIcon"
          />
        </b-button>
      </template>
      <!-- Rectificacion -->
      <template #cell(descargaRectificacion)="data">
        <b-button
          v-if="data.item.guidArchivoPedimentoBorradorRectificacion"
          variant="success"
          @click="download(data.item.guidArchivoPedimentoBorradorRectificacion)"
        >
          {{ $t('dashboards.auditoriaMolex.rectifications.download') }}
          <feather-icon
            icon="DownloadCloudIcon"
          />
        </b-button>
      </template>

    </b-table>
  </b-card>
</template>

<script>
import i18n from '@/libs/i18n'
import {
  BCard,
  BTable,
  BButton,
  BFormCheckbox,
} from 'bootstrap-vue'

export default {
  components: {
    BCard,
    BTable,
    BButton,
    BFormCheckbox,
  },
  props: {
    rectifications: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      fields: [
        { key: 'numeroReferencia', label: i18n.t('dashboards.auditoriaMolex.rectifications.referenceNumber') },
        { key: 'numeroPedimento', label: i18n.t('dashboards.auditoriaMolex.rectifications.referencePediment') },
        { key: 'claveDocumento', label: i18n.t('dashboards.auditoriaMolex.rectifications.documentKey') },
        { key: 'fechaRectificacion', label: i18n.t('dashboards.auditoriaMolex.rectifications.dateRectification') },
        { key: 'pedimentoOriginal', label: i18n.t('dashboards.auditoriaMolex.rectifications.originalPediment') },
        { key: 'fechaPagoOriginal', label: i18n.t('dashboards.auditoriaMolex.rectifications.originalFP') },
        { key: 'cargoDe', label: i18n.t('dashboards.auditoriaMolex.rectifications.inCharge') },
        { key: 'observacionesPedimento', label: i18n.t('dashboards.auditoriaMolex.rectifications.observations') },
        { key: 'descargaOriginal', label: i18n.t('dashboards.auditoriaMolex.rectifications.downloadOriginal') },
        { key: 'descargaRectificacion', label: i18n.t('dashboards.auditoriaMolex.rectifications.downloadRectification') },
      ],
    }
  },
  methods: {
    download(idArchivo) {
      window.open(`${process.env.VUE_APP_ADUACORE_API_URL}/api/Archivos/ExpedienteDigital/${idArchivo}`, '_blank')
    },
  },
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
