<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title class="mb-sm-0 mb-2">
        {{ title }}
      </b-card-title>
      <b-card-sub-title class="mt-1">
        {{ subtitle }}
      </b-card-sub-title>
    </b-card-header>

    <b-card-body>
      <vue-apex-charts
        ref="radialChar"
        type="radialBar"
        height="400"
        :options="chartOptions"
        :series="series"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import i18n from '@/libs/i18n'
import {
  BCard, BCardTitle, BCardBody, BCardHeader, BCardSubTitle,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
// import apexChatData from './apexChartData'

export default {
  components: {
    VueApexCharts,
    BCard,
    BCardTitle,
    BCardBody,
    BCardHeader,
    BCardSubTitle,
  },
  props: {
    title: {
      required: true,
      type: String,
    },
    subtitle: {
      required: true,
      type: String,
    },
  },
  data() {
    return {
      // apexChatData,
      series: [],
      chartOptions: {
        colors: [
          '#ffe700',
          '#00d4bd',
          '#826bf8',
          '#2b9bf4',
          '#FFA1A1',
        ],
        plotOptions: {
          radialBar: {
            size: 185,
            hollow: {
              size: '25%',
            },
            track: {
              margin: 15,
            },
            dataLabels: {
              name: {
                fontSize: '2rem',
                fontFamily: 'Montserrat',
              },
              value: {
                fontSize: '1rem',
                fontFamily: 'Montserrat',
                show: true,
                formatter(val) {
                  // eslint-disable-next-line radix
                  return `${parseInt(val)}`
                },
              },
              // total: {
              //   show: true,
              //   fontSize: '1rem',
              //   label: 'Comments',
              //   formatter() {
              //     return '80%'
              //   },
              // },
            },
          },
        },
        legend: {
          show: true,
          position: 'bottom',
          horizontalAlign: 'center',
        },
        stroke: {
          lineCap: 'round',
        },
        labels: [
          i18n.t('dashboards.auditoriaMolex.radialBar.recticsCLLA'),
          i18n.t('dashboards.auditoriaMolex.radialBar.recticsMOLEX'),
          i18n.t('dashboards.auditoriaMolex.radialBar.operations'),
        ],
      },
    }
  },
  methods: {
    updateChar(series) {
      /*eslint-disable */ 
      // this.chartOptions = {
      //   ...this.chartOptions, ...
      //   {
      //     xaxis: {
      //     categories: periodos
      //   }
      // }}

      this.$refs.radialChar.updateSeries(series)
    },
  },   
}
</script>
